<template>
  <div>
    <CRow>
  <CCol col="12" xl="12">
    <CCard>
      <CCardHeader class="">
      <CRow>
      <CCol col="6" xl="6">
        <h6 class="w-100 text-muted">Nová položka formulára </h6>
         <h2 class="mb-0 page-title" v-if="form">{{form.title}}</h2>
      </CCol>
      <CCol col="6" xl="6">
           <div class="text-right system-options w-100 d-block" style="">
            <router-link :to="'/form-items/' + this.mid"  >
                                        <template>
                                            <a-button type="primary" >Položky</a-button>
                                        </template>
                                    </router-link>
          </div>
      </CCol>
      </CRow>
      </CCardHeader>
      <CCardBody>
                    <a-card shadow type="secondary"  style="margin:0; margin-top:30px">
                        <template>
                            <form @submit.prevent="handleSubmit">


                                <div class="mb-4">
                                    <label class="form-control-label">Názov poľa</label>
                                    <a-input placeholder="Názov poľa" v-model="newField.title"></a-input>
                                </div>
                                <div class="mb-4">
                                    <label class="form-control-label">Placeholder</label>
                                    <a-input placeholder="Dočasný text" v-model="newField.placeholder"></a-input>
                                </div>

                                <div class="mb-4">
                                    <label class="form-control-label">Typ poľa</label>
                                    <a-select defaultValue="1" v-model="newField.type" @change="handleChange" :options="types"/>
                                </div>

                                <div v-if="newField.type === '2'" class="mb-4">
                                  <div style="margin-bottom:15px">
                                      <a-textarea rows="10" v-model="newField.options" ></a-textarea>
                                    <div class="description">Všetky hodnoty, ktoré môže toto pole obsahovať. Vložte jednu hodnotu na riadok, vo formáte kľúč|menovka.</div>
                                  </div>

                                    <a-radio-group :options="plainOptions" v-model="newField.subtype"/>
                                </div>

                                <div class="mb-4">
                                    <label class="form-control-label">Štýl</label>
                                    <a-select defaultValue="1" v-model="newField.style" @change="handleChange" :options="styles"/>
                                </div>


                                <template v-if="newField.type === '8'">
                                    <div class="mb-4">
                                        <label class="form-control-label">Typ obsahu</label>
                                        <div class="mb-3">
                                        <a-radio-group v-decorator="['radio-group']" v-model="newField.ct">
                                            <a-form-item :label="k.title" :key="k" :label-col="{ span: 22 }" :wrapper-col="{ span: 2 }" v-for="k in contenttypes">
                                                <a-radio class="mb-3" :value="k.id" />
                                            </a-form-item>
                                        </a-radio-group>
                                    </div>
                                    </div>
                                </template>
                                <template v-if="newField.type === '12'">
                                    <div class="mb-4">
                                        <label class="form-control-label">Slovníky taxonómie</label>
                                        <div class="mb-3">

                                        <a-radio-group v-decorator="['radio-group']" v-model="newField.ct">
                                            <a-form-item :label="k.title" :key="k" :label-col="{ span: 22 }" :wrapper-col="{ span: 2 }" v-for="k in taxonomies">
                                                <a-radio class="mb-3" :value="k.id" />
                                            </a-form-item>
                                        </a-radio-group>
                                        <h6 class="heading-small text-muted mb-4">Multi pole</h6>
                                        <a-switch @change="onChange" />
                                        </div>            </div>
                                </template>
                                <div class="mb-4">
                                    <label class="form-control-label">Povinná položka</label>
                                    <a-checkbox class="mb-3" @input="checkboxChange" :checked="newField.required">Položka bude povinná</a-checkbox>
                                </div>
                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
                    </CCardBody>
                    </CCard>
                    </CCol>
                    </CRow>
    </div>
</template>
<script>
    import {API_URL} from "../../api";
    import {authHeader} from "../../_helpers";
    import {dataService} from "../../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";
    import ARadioGroup from "ant-design-vue/es/radio/Group";
    import ATextarea from "ant-design-vue/es/input/TextArea";

    Vue.use(FormItem);

    let id = 0;
    const plainOptions =  [
        {"value" : '0' ,"label" : 'Výberovník'},
        {"value" : '1' ,"label" : 'Radio'},
        {"value" : '2' ,"label" : 'Checkbox'},
        ];
    export default {
        name: 'formitem',
        components: {ATextarea, ARadioGroup, AFormItem},
        props: ['mid','id'],
        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Dlhý text'},
                    {"value" : '4' ,"label" : 'Celé číslo'},
                    {"value" : '5' ,"label" : 'Desatiné číslo'},
                    {"value" : '6' ,"label" : 'E-mail'},
                    {"value" : '7' ,"label" : 'Dátum'},
                    {"value" : '8' ,"label" : 'Hodnotenie'},
                    {"value" : '9' ,"label" : 'Markup'},
                    {"value" : '10' ,"label" : 'Skrytý názov stránky'},
                ],
                styles: [
                    {"value" : 'width33' ,"label" : '33% šírky'},
                    {"value" : 'width50' ,"label" : '50% šírky'},
                    {"value" : 'width100' ,"label" : '100% šírky'},
                ],
                plainOptions,
                newField: {
                    title : null,
                    type : null,
                    subtype: 0,
                    options : [],
                    opp: [],
                    slug : null,
                    placeholder : null,
                    menuid : this.mid
                },
                form:{},
                loading: false,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                head: authHeader(),
                send: API_URL,
                menuId: {
                    uid: this.mid
                },
                menu: {
                    title: '',
                    menuid: this.mid,
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },
        methods: {
            checkboxChange(e)
            {
               if(e)
                   this.newField.required = 1;
               else
                   this.newField.required = 0;
            },
            confirm(e) {
                dataService.axiosDelete(this.$options.name, e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            bool(v) {
                return v === "true" || v === "1";
            },
            cancel() {
            },
            handleChange() {

            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },
             add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            handleSubmit() {
                let $menu_data = this.newField;
                dataService.axiosPost(this.$options.name, $menu_data).then(results => {
                    if (results.data.success) {
                        this.$message.success('Obsah bol upravený');
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }


                });
            },
        },
        mounted() {
            if (!isNaN(this.id)) {
                dataService.axiosFetch(this.$options.name, this.id).then(results => {
                    this.newField = results[0];
                    this.newField.options = results[0].options;


                });
            }

            dataService.axiosFetch('form', this.mid).then(results => {
                    this.form = results;

                });



        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
