<template>
    <div>
      <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader class="">
        <CRow>
        <CCol col="6" xl="6">
          <h6 class="w-100 text-muted"><span v-if="id">Upravuje sa fromulár</span><span v-else>Pridávate formulár </span> </h6>
           <h2 class="mb-0 page-title" v-if="contenttype">{{contenttype.title}}</h2>
        </CCol>
        <CCol col="6" xl="6">
             <div class="text-right system-options w-100 d-block" style="">
              <router-link  :to="'/form-items/' + contenttype.id"> <a-button>Položky formulára</a-button></router-link>

            </div>
        </CCol>
        </CRow>
        </CCardHeader>
        <CCardBody>

                    <a-card shadow type="secondary" style="margin:0; margin-top:30px" >
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                        <div class="col-lg-12">
                                          <a-form-item label="Názov" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.title"
                                            />
                                            </a-form-item>
                                            <a-form-item label="Predmet" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.subject"/>
                                                      </a-form-item>
                                                      <a-form-item label="Email na prijatie formulára"
                                          :label-col="formItemLayout.labelCol"
                                                    :wrapper-col="formItemLayout.wrapperCol"
                                                    >
                                            <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="contenttype.email"
                                            />
                                            </a-form-item>

                                            <a-form-item label="Slug - reťazec bez diakritiky" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
                                              <a-input input-classes="form-control-alternative" v-model="contenttype.slug"/>
                                            </a-form-item>

                                            <a-form-item v-if="formItems"
                                                    :label-col="formItemLayout.labelCol"
                                                    :wrapper-col="formItemLayout.wrapperCol"
                                                    label="Kópia na">
                                                <a-select mode="single" v-model="contenttype.secondemail"  placeholder="Vyberte si z možností">
                                                <a-select-option :key="0">Neodosielať</a-select-option>
                                                <a-select-option v-for="op in formItems" :key="op.id">{{op.title}}</a-select-option>
                                            </a-select>
                                            </a-form-item>

                                            <a-form-item v-if="formItems"
                                                    :label-col="formItemLayout.labelCol"
                                                    :wrapper-col="formItemLayout.wrapperCol"
                                                    label="Druhá kópia na">
                                                <a-select mode="single" v-model="contenttype.thirdemail"  placeholder="Vyberte si z možností">
                                                <a-select-option :key="0">Neodosielať</a-select-option>
                                                <a-select-option v-for="op in formItems" :key="op.id">{{op.title}}</a-select-option>
                                            </a-select>
                                            </a-form-item>

                                            <a-form-item v-if="formItems"
                                                    :label-col="formItemLayout.labelCol"
                                                    :wrapper-col="formItemLayout.wrapperCol"
                                                    label="Jazyk">
                                                <a-select style="width: 100%"  defaultValue="sk"  v-model="contenttype.language" :options="languages"/>

                                            </a-form-item>
                                        </div>
                                    </div>
                                </div>


                                <!-- Description -->
                                <div class="text-right">
                                    <button class="btn btn-primary">Save</button>
                                </div>

                            </form>
                        </template>
                    </a-card>
              </CCardBody>
              </CCard>
              </CCol>
              </CRow>
    </div>
</template>
<script>
    import {API_URL} from "../api";
    import {authHeader} from "../_helpers";
    import {dataService} from "../_services";
    import Vue from "vue"
    import FormItem from 'ant-design-vue'
    import AFormItem from "ant-design-vue/es/form/FormItem";

    Vue.use(FormItem);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            sorter: true,
            width: '20%',
        },
        {
            title: 'Typ poľa',
            dataIndex: 'type',
            scopedSlots: {customRender: 'cttype'},
        },
        {
            title: 'Slug',
            dataIndex: 'slug',
        },
        {
            align: 'right',
            scopedSlots: {customRender: 'name'},
        }
    ];
    let id = 0;
    export default {
        name: 'formed',
        components: {AFormItem},
        props: ['id'],

        data() {
            return {
                data: [],
                colorsto: JSON.parse(localStorage.colors),
                newField: {
                    title : null,
                    type : null,
                    blocks: [],
                    options : [],
                    multi: false,
                    slug : null,
                    id : this.id
                },
                loading: false,
                defaultLanguage: 'sk',
                languages:[],
                columns,
                types: [
                    {"value" : '1' ,"label" : 'Text'},
                    {"value" : '2' ,"label" : 'Výberovník'},
                    {"value" : '3' ,"label" : 'Obrázok'},
                    {"value" : '4' ,"label" : 'Dlhý text'},
                    {"value" : '5' ,"label" : 'Celé číslo'},
                    {"value" : '6' ,"label" : 'Desatiné číslo'},
                    {"value" : '7' ,"label" : 'Logická hodnota Áno/Nie'},
                    {"value" : '8' ,"label" : 'Priradenie pod typ obsahu'},
                    {"value" : '9' ,"label" : 'Dynamická hodnota'},
                    {"value" : '10' ,"label" : 'Builder'},
                    {"value" : '11' ,"label" : 'Galéria obrázkov'},
                    {"value" : '12' ,"label" : 'Priradenie pod taxonómiu'},
                    {"value" : '13' ,"label" : 'Priradenie pod taxonómiu s násobkom'},
                    {"value" : '14' ,"label" : 'Dátum'},
                    {"value" : '15' ,"label" : 'Rating'},
                ],
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 4 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: { span: 24, offset: 0 },
                        sm: { span: 20, offset: 4 },
                    },
                },
                formItems:[],
                head: authHeader(),
                send: API_URL,
                contenttypeId: {
                    uid: this.id
                },
                contenttypes: [],
                contenttype: {
                    title: '',
                    type: '',
                    secondemail: 0,
                    thirdemail: 0,
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
            this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
        },       computed: {
            dragOptions() {
                return {
                    animation: 0,
                    group: "description",
                    disabled: false,
                    ghostClass: "ghost"
                };
            }
        },
        methods: {
            confirm(e) {
                dataService.axiosDelete('form', e).then(results => {
                    if (results) {
                        this.data.splice(this.data.findIndex(x => parseInt(x.id) === parseInt(e)), 1);
                    }
                });
            },
            onChange(checked) {
                this.newField.multi = checked;
            },
            cancel() {
            },
            saveReorder()
            {
                dataService.axiosPost('reorderblocks/' + this.id, this.contenttype.blocks).then(() => {

                });
            },
            handleChange() {

            },
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },

            updateBlocks() {

            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            handleSubmit() {
                let $contenttype_data = this.contenttype;
                dataService.axiosPost('form', $contenttype_data).then(results => {

                    if(results.data)
                    {

                this.$router.push('/form/'+results.data.message)

                    }

                });
            },
            handleNewFieldSubmit() {
                let $contenttype_data = this.newField;


                dataService.axiosPost("form/newfield", $contenttype_data).then(results => {
                    if(results.data.data)
                    {
                        this.contenttype.fields = results.data.data.fields;
                        this.$router.push('/forms')
                    }


                });
            },
        },
        mounted() {


   dataService.axiosFetch('languages').then(results => {
                   results.forEach(obj => {
                    if(obj.def === "1")
                    this.defaultLanguage = obj.short;
                });

      var roleops = [];
                         results.forEach(obj => {
                               roleops.push({"label" : obj.title , "value" : obj.short});
                         });

                        this.languages = roleops;

            });


            if (!isNaN(this.id)) {
                dataService.axiosFetch('form', this.id).then(results => {
                    this.contenttype = results;
if(!this.contenttype.subject)
    this.contenttype.subject = this.contenttype.title;


                    dataService.axiosFetch('formitems',this.id).then(results => {

                        var roleops = [];
                         results.forEach(obj => {
                             if(obj.type === "6")
                             roleops.push({"title" : obj.title , "id" : obj.id});
                             if(obj.type === "2" && obj.subtype == "0")
                             roleops.push({"title" : obj.title , "id" : obj.id});
                         });

                        this.formItems = roleops;

                    });
                });
            }
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }
    .element
    {display:block;width:100%; border-top-left-radius: 5px;border-top-right-radius:5px;overflow:hidden;}

    .element
    {
        padding:0;
        border-bottom:1px solid #fff;
        border-radius:0;
    }
    .manager
    {width:800px}
    .top-holder
    {width:100%;border:1px solid #eceff3; padding:15px   }
    .element-image
    {width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}

    .element .handle
    {font-size:22px;padding:5px}
    .element span
    {width:100%; display:block;color: #33a6ba;  text-align:left; min-height:30px; padding-left:5px; font-size:14px; background-color: #a3e4ff;}
    .edit-button
    {float:right; margin:0; margin-left:2px;border-radius:0px; background-color: rgba(0, 0, 0, 0.04); border: 0; color:#fff;
    }
    .ant-select-selection--single
    {width:100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;}

</style>
